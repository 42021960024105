/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Link } from "gatsby"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : { className: "" }
}

const ExactNavLink = props => <Link getProps={isActive} {...props} />

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.StrictMode>
      <div data-theme="night">
        <div className="drawer drawer-mobile">
          <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col items-top justify-top bg-neutral">
            {/* <!-- Page content here --> */}
            <main className="bg-neutral flex-auto">{children}</main>
          </div>
          <div className="drawer-side">
            <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
            <ul className="menu p-4 w-60 bg-base-100 text-base-content">
              {/* <!-- Sidebar content here --> */}
              <div className="pb-8 border-b border-base-300">
                <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
              </div>
              <li className="mt-2">
                <ExactNavLink to="/">Recent Projects</ExactNavLink>
              </li>
              <li>
                <ExactNavLink to="/new-project">New Project</ExactNavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.StrictMode>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
