import * as React from "react"
import { Link } from "gatsby"


const ProjectCard = ({ id, name }) => {
  return (
    <div className="flex flex-wrap p-5">
      <div className="card w-64 bg-base-100 shadow-xl">
        <figure>    <img
          src="../images/gold_mask_example.jpg"
          alt="My Image"
        /></figure>

        <div className="card-body">
          <h2 className="card-title">{name}</h2>
          {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
          <div className="card-actions justify-end">
            <Link
              to="/editor/editor-scan/"
              state={{ project_name: name, project_id: id }}
            >
              <button className="btn btn-outline btn-primary">Open</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
