import React, { useState, useEffect } from "react"
import Layout from "../components/layout"

import ProjectCard from "../components/project-card"
import RouteGuard from "../components/auth/route-guard"

import { Search } from "react-bootstrap-icons"
import { navigate } from "gatsby"

const IndexPage = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem("token")
    console.log("Headers:", {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    })
    fetch(`${process.env.BACKEND_API_URL}/hub/project_overview`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status === 401) {
          // Redirect to login page if Unauthorized
          localStorage.removeItem("token")
          navigate("/login")
          return
        }
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          )
        }
        return response.json()
      })
      .then(actualData => {
        console.log(actualData)
        setData(actualData)
        setError(null)
      })
      .catch(err => {
        setError(err.message)
        setData(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <RouteGuard>
      <Layout>
        <div className="m-4 p-3 bg-primary flex flex-no-wrap justify-center items-center">
          <div className="form-control">
            <div className="input-group">
              <input
                type="text"
                placeholder="Search…"
                className="input input-bordered w-96"
              />
              <button className="btn btn-square">
                <Search className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
        {data &&
          data.map(({ project_id, project_name }) => (
            <ProjectCard key={project_id} id={project_id} name={project_name} />
          ))}
      </Layout>
    </RouteGuard>
  )
}

export default IndexPage

export const Head = () => <title>Mastrcheif Hub</title>
