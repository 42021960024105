// src/route-guard.js
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

const RouteGuard = ({ children }) => {
  const [token, setToken] = useState(null)

  useEffect(() => {
    console.log(token)
    console.log("Token:", localStorage.getItem("token"))
    if (!localStorage.getItem("token")) {
      setToken(null)
      navigate("/login")
      return null
    } else {
      setToken(localStorage.getItem("token"))
    }
  }, [])
  
  return <>{children}</>
}

export default RouteGuard
